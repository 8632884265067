import { MenuIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { AppLinks } from "./AppLinks";
import { HomepageTitle } from "./HomepageTitle";

export const MobileMenu = () => {
  const [visible, setVisible] = useState(false);
  const visibilityCss = visible ? "visible" : "invisible";
  function onClickHandler() {
    setVisible(!visible);
  }
  return (
    <>
      <div
        className={`fixed ${visibilityCss} -top-8 left-0 h-screen w-screen bg-white/90 z-40`}
      >
        <div className="flex flex-col space-y-8 py-10 justify-center items-center mt-32 text-3xl">
          <AppLinks />
        </div>
      </div>
      <button
        onClick={onClickHandler}
        aria-label={`${visible ? "Expand" : "Collapse"} the main menu.`}
      >
        <div className="fixed w-full left-1/2 top-4 flex z-50 justify-between px-4 md:hidden -translate-x-1/2 mix-blend-revert bg-slate-50 shadow-md">
          <span>
            <HomepageTitle />
          </span>
          <div className="relative h-10 w-10 mix-blend-difference">
            <MenuIcon
              className="border-2 z-50 absolute top-0 left-0"
              aria-hidden={true}
              tabIndex={0}
              color="white"
            />
          </div>
        </div>
      </button>
    </>
  );
};
