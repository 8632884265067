import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobeAltIcon } from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";
import { NavigationRoutes } from "../Routes";

const enum Languages {
  EN = "en",
  EL = "el",
}

export const LanguageSwitcher = () => {
  const [visible, setVisible] = useState(false);
  const { language } = useLanguageSwitcher();
  const location = useLocation();

  const englishPath = useMemo(
    () =>
      `${pathnameToValidNavigationRoute(location.pathname)}?lng=${
        Languages.EN
      }`,
    [location.pathname]
  );

  const greekPath = useMemo(
    () =>
      `${pathnameToValidNavigationRoute(location.pathname)}?lng=${
        Languages.EL
      }`,
    [location.pathname]
  );

  return (
    <div style={{ position: "relative" }}>
      <button
        aria-label="Language switcher"
        title={languageToDisplayText(language as Languages)}
        onClick={() => setVisible(!visible)}
        className="bg-gray-200 rounded-md py-1 pl-2 pr-4 shadow text-zinc-800 text-sm cursor-pointer flex row-auto items-center"
      >
        <GlobeAltIcon className="h-4 mr-2" />
        {languageToDisplayText(language as Languages)}
      </button>
      <div
        style={{ position: "absolute", display: visible ? "block" : "none" }}
        className="bg-white shadow rounded-sm mt-2"
      >
        <ul>
          <li className="hover:border-l-2 p-2 cursor-pointer">
            <a href={englishPath}>
              <span>{languageToDisplayText(Languages.EN)}</span>
            </a>
          </li>
          <li className="hover:border-l-2 p-2  cursor-pointer">
            <a href={greekPath}>
              <span>{languageToDisplayText(Languages.EL)}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

function pathnameToValidNavigationRoute(pathname: string) {
  return (
    Object.values(NavigationRoutes).find((r) => {
      if (NavigationRoutes.home === r) {
        return false;
      }
      return pathname.includes(r);
    }) ?? NavigationRoutes.home
  );
}

export function stringToAvailableLanguage(requestedLanguage: string) {
  switch (requestedLanguage) {
    case Languages.EN:
    case Languages.EL:
      return requestedLanguage;
    default:
      return Languages.EN;
  }
}

function languageToDisplayText(language: Languages) {
  switch (language) {
    case Languages.EL:
      return "Ελληνικά";
    case Languages.EN:
      return "English";
  }
}

export function stringLocaleToLanguage(locale: string): Languages {
  switch (locale.split("-")[0]) {
    case "el":
      return Languages.EL;
    default:
      return Languages.EN;
  }
}

export function useLanguageSwitcher() {
  const { i18n } = useTranslation();

  // const setLanguage = useCallback(
  //   (wantedLanguage: Languages) => {
  //     const language = stringToAvailableLanguage(wantedLanguage);
  //     window.localStorage.setItem("language", language);
  //     i18n.changeLanguage(language);
  //   },
  //   [i18n]
  // );

  return {
    language:
      stringLocaleToLanguage(window.localStorage.getItem("language") ?? "") ??
      i18n.language,
  };
}
