import { useTranslation } from "react-i18next";
import { NavigationRoutes } from "../Routes";
import { LanguageSwitcher } from "./LanguageSwitcher";
import { LinkTopMenu } from "./LinkTopMenu";

export function AppLinks() {
  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
    });
  }
  const { t } = useTranslation();
  return (
    <>
      <LinkTopMenu to={NavigationRoutes.home}>{t("top_menu_home")}</LinkTopMenu>
      <LinkTopMenu to={NavigationRoutes.aboutUs} onClick={handleScrollToTop}>
        {t("top_menu_about")}
      </LinkTopMenu>
      <LinkTopMenu to={NavigationRoutes.contact} onClick={handleScrollToTop}>
        {t("top_menu_contact")}
      </LinkTopMenu>
      <LanguageSwitcher />
    </>
  );
}
