import { Footer } from "../components/Footer";
import { MetaTags } from "../components/MetaTags";
import { HeaderSubsection } from "../components/HeaderSubsection";
import { Trans, useTranslation } from "react-i18next";

export const Contact = () => {
  const { t } = useTranslation();
  return (
    <>
      <MetaTags
        pageTitle={t("meta_page_title_contact")}
        pageDescription={t("meta_page_description_contact")}
        canonicalUrlPath="contact-information"
        socialMediaTitle={t("social_media_title")}
      />
      <div className="bg-black">
        <HeaderSubsection
          title={t("contact_title")}
          description={
            <span className="prose text-xl text-center">
              <p>
                <Trans i18nKey="contact_description1">
                  To directly book with us you can contact us on{" "}
                  <a
                    href="mailto:info@thearchontariki.com"
                    aria-details="Email link to inquire on booking requests."
                  >
                    info@thearchontariki.com
                  </a>
                  <br /> or call{" "}
                  <a
                    href="tel:+306936558908"
                    aria-details="Mobile telephone number link."
                  >
                    +30 693 655 8908
                  </a>
                  <a
                    href="tel:+302375031406"
                    aria-details="Landline telephone number link."
                  >
                    +30 237 503 1406
                  </a>
                </Trans>
              </p>
              <p>
                <Trans i18nKey="contact_description2">
                  You can also contact us on{" "}
                  <a
                    href="https://www.facebook.com/thearchontariki"
                    target="_blank"
                    rel="noreferrer"
                    aria-details="Link to open The Archontariki Facebook page."
                    className="text-blue-700"
                  >
                    Facebook
                  </a>
                </Trans>
              </p>
            </span>
          }
        />
      </div>
      <div className="h-12 md:h-12" />
      <Footer />
    </>
  );
};
