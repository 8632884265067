import { Helmet } from "react-helmet";

export function MetaTags(props: {
  pageTitle: string;
  pageDescription: string;
  canonicalUrlPath: string;
  socialMediaTitle: string;
}) {
  return (
    <Helmet>
      <link
        rel="alternate"
        hrefLang="en"
        href={`https://thearchontariki.com/en-GB/${props.canonicalUrlPath}`}
      />
      <link
        rel="alternate"
        hrefLang="el"
        href={`https://thearchontariki.com/el-GR/${props.canonicalUrlPath}`}
      />
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`https://thearchontariki.com/${props.canonicalUrlPath}`}
      />
      <title>{props.pageTitle}</title>
      <meta name="description" content={props.pageDescription} />
      {/* Google meta tag */}
      <meta itemProp="name" content={props.socialMediaTitle} />
      <meta itemProp="description" content={props.pageDescription} />
      <meta
        itemProp="image"
        content="https://thearchontariki.com/img/meta/preview.jpeg"
      />
      {/* Facebook meta tag */}
      <meta property="og:url" content="https://thearchontariki.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.socialMediaTitle} />
      <meta property="og:description" content={props.pageDescription} />
      <meta
        property="og:image"
        content="https://thearchontariki.com/img/meta/preview.jpeg"
      />
      {/* Twitter meta tag */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={props.socialMediaTitle} />
      <meta name="twitter:description" content={props.pageDescription} />
      <meta
        name="twitter:image"
        content="https://thearchontariki.com/img/meta/preview.jpeg"
      />
      <link
        rel="canonical"
        href={`https://thearchontariki.com/${props.canonicalUrlPath}`}
      />
      <script type="application/ld+json">
        {`{
  "@context" : "http://schema.org",
  "@type" : "Hotel",
  "name" : "The Archontariki",
 "url" : "https://thearchontariki.com",
 "sameAs" : [
   "https://www.facebook.com/thearchontariki"
   ],
  "address": {
    "@type": "PostalAddress",
    "addressLocality": "Sithonia",
    "addressRegion": "Halkidiki",
    "streetAddress": "Ormos Panagias",
    "postalCode": "63078",
    "addressCountry": "GR"
  },
  "geo": {
    "latitude": "40.2341382",
    "longitude": "23.7303514"
  }
}`}
      </script>
    </Helmet>
  );
}
