import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { NavigationRoutes } from "./Routes";
import ReactPixel from "react-facebook-pixel";
import { useFirebaseAnalyticsCurrentScreen } from "./hooks/firebase";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { i18EnResource } from "./trasnlations/translations";
import detector from "i18next-browser-languagedetector";

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ...i18EnResource,
    },
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
    detection: {
      lookupLocalStorage: 'language'
    }
  });

function App() {
  useEffect(() => {
    ReactPixel.init("475025574358937", undefined, {
      autoConfig: true,
      debug: false,
    });
    ReactPixel.pageView();
  }, []);
  return (
    <div id="App">
      <Router>
        <CurrentPageTrackerComponent />
        <Routes>
          <Route path={NavigationRoutes.home} element={<Home />} />
          <Route path={NavigationRoutes.aboutUs} element={<About />} />
          <Route path={NavigationRoutes.contact} element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

const CurrentPageTrackerComponent = () => {
  useFirebaseAnalyticsCurrentScreen();
  return null;
};

export default App;
