export const ButtonBorder = (props: { text: string }) => {
  return <div className="border-2 p-4">{props.text}</div>;
};

export const BookNowButton = () => {
  return (
    <button>
      <a
        href="https://www.booking.com/hotel/gr/archontariki-ormos-panagias.en-gb.html"
        target="_blank"
        rel="noreferrer"
      >
        <div className="bg-black px-16 py-4 text-slate-50 font-semibold transition hover:bg-sky-600 ease-in cursor-pointer">
          Book now
        </div>
      </a>
    </button>
  );
};
