import { ReactNode, useRef } from "react";
import { useFadeOnIntersectWithViewport } from "../intersection-hooks";
import { Menu } from "./Menu";
import { MobileMenu } from "./MobileMenu";
import RoomImage from "../images/room_2.jpeg";

export const HeaderSubsection = (props: {
  title: string;
  description: ReactNode;
}) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  useFadeOnIntersectWithViewport([titleRef, textRef]);
  return (
    <div className="w-screen flex justify-center items-center">
      <div className="absolute h-screen w-full md:w-full right-0 top-0 overflow-hidden">
        <div className="relative h-5/6 w-full">
          <img
            src={RoomImage}
            alt=""
            className="object-cover"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div>
      <header className="relative w-full h-screen flex items-center justify-center">
        <span>
          <div className="absolute top-6 w-full hidden md:flex justify-center">
            <Menu />
          </div>
          <MobileMenu />
        </span>
        <div className="w-full h-full md:mt-0 flex justify-center items-end">
          <div className="md:w-1/2 min-h-64 bg-white flex items-center shadow-md p-8 mb-24 flex-col space-y-8">
            <h1 className="prose text-3xl">{props.title}</h1>
            {props.description}
          </div>
        </div>
      </header>
    </div>
  );
};
