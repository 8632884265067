import { useRef, useState } from "react";
import { Footer } from "../components/Footer";
import { GoogleMapsMap, KnownDirections } from "../components/GoogleMapsMap";
import { MetaTags } from "../components/MetaTags";
import { HeaderSubsection } from "../components/HeaderSubsection";
import {
  useFadeOnIntersectWithViewport,
  useMoveUpOnIntersectWithViewport,
} from "../intersection-hooks";
import BeachSvg from "../images/svg/beach-svgrepo-com.svg";
import RestaurantSvg from "../images/svg/restaurant-svgrepo-com.svg";
import ShopSvg from "../images/svg/shop-svgrepo-com.svg";
import SageImage from "../images/the-archontariki-garden-sage.jpeg";
import RoomImage from "../images/the-archontariki-room-view.jpeg";
import FishImage from "../images/greek-fish.jpg";
import { Trans, useTranslation } from "react-i18next";

function TodoSection() {
  const el1 = useRef(null);
  const el2 = useRef(null);
  const el3 = useRef(null);
  const el4 = useRef(null);
  useMoveUpOnIntersectWithViewport([el1, el2, el3], {
    delay: 500,
  });
  useFadeOnIntersectWithViewport([el4]);
  const { t } = useTranslation();
  return (
    <section className="bg-black flex flex-col justify-center items-center space-y-8">
      <div ref={el4} className="w-full h-full flex items-end justify-center">
        <div className="w-11/12 text-center md:w-2/3 z-20 flex flex-col text-white items-center space-y-8">
          <h2 className="text-5xl font-serif">{t("about_block1_title")}</h2>
          <span className="text-lg">
            <Trans>{t("about_block1_body")}</Trans>
          </span>
        </div>
      </div>
      <div className="container md:relative md:flex md:justify-center md:items-center md:min-h-[650px] space-y-8">
        <div
          ref={el1}
          className="bg-white w-full md:w-96 p-4 md:m-16 h-[500px] shadow-md md:absolute z-10 top-0"
        >
          <div className="flex h-full w-full max-h-[500px]">
            <img
              src={SageImage}
              className="object-cover w-full"
              alt="A view from the Archontariki Studio's garden"
            />
          </div>
        </div>
        <div
          ref={el2}
          className="bg-white w-full md:w-96 h-96 p-4 md:m-16 shadow-md md:absolute left-0 z-0 top-24"
        >
          <div className="flex h-full w-full">
            <img
              src={RoomImage}
              className="h-full w-full object-cover"
              alt="One of our uniquely designed luxury stuio in Archontariki Studios"
            />
          </div>
        </div>
        <div
          ref={el3}
          className="bg-white w-full md:w-96 h-96 p-4 md:m-16 shadow-md md:absolute right-0 z-0 top-24"
        >
          <div className="flex h-full w-full">
            <img
              src={FishImage}
              className="h-full w-full object-cover"
              alt="Fresh fish fished in Greek waters in Ormos Panagias"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function AmenitiesComponent() {
  const el1 = useRef(null);
  const el2 = useRef(null);
  const el3 = useRef(null);
  const mapsRef = useRef<HTMLDivElement>(null);

  useMoveUpOnIntersectWithViewport([el1, el2, el3], {
    delay: 500,
  });

  const scrollToMaps = () => {
    window.scrollTo({
      top: mapsRef.current?.offsetTop,
      behavior: "smooth",
    });
  };

  const handleDirectionRequest = (direction: KnownDirections) => {
    console.log(mapsRef.current?.scrollTop);
    console.log(mapsRef.current?.offsetTop);
    scrollToMaps();
    setDirectionToShow(direction);
  };

  const [directionToShow, setDirectionToShow] =
    useState<KnownDirections>("none");
  const { t } = useTranslation();
  return (
    <section className="bg-white flex flex-col justify-center items-center space-y-8">
      <div className="w-full h-full flex items-end justify-center">
        <div className="w-11/12 text-center md:w-2/3 z-20 flex flex-col items-center space-y-4">
          <h2 className="text-2xl font-serif">{t("about_block2_title")}</h2>
          <span className="text-lg text-justify md:w-4/6">
            <p>{t("about_block2_body")}</p>
          </span>
          <h3 className="text-2xl font-serif">{t("about_block3_title")}</h3>
          <span className="text-lg text-justify md:w-4/6">
            <p>
              <Trans i18nKey={"about_block3_body"}>
                Ormos Pagagias is just <u>1 hour</u> away from the Thessaloniki
                Makedonia Airport (
                <a
                  href="https://www.skg-airport.gr/en"
                  target={"_blank"}
                  style={{ color: "blue" }}
                  rel="noreferrer"
                  aria-label="Link to Thessaloniki Makedonia Airport's website"
                >
                  SKG
                </a>
                ). With multiple car
                <a
                  href="https://www.google.com/search?q=rent+a+car+in+thessaloniki"
                  target={"_blank"}
                  style={{ color: "blue" }}
                  rel="noreferrer"
                  aria-label="Link to Google Search for car rental in Thessaloniki"
                >
                  renting locations nearby
                </a>
                , getting to Archontariki is a short drive away.
                <br />
                <br />
                From shops to taverns, to the beach, everything is then just in
                walking distance.
                <strong>
                  Select one of the bellow options to see the distance from the
                  Archontariki.
                </strong>
              </Trans>
            </p>
          </span>
        </div>
      </div>
      <div className="flex flex-col items-center space-y-2">
        <div className="relative container flex justify-center items-center min-h-[500px] md:space-x-8 space-y-8 md:space-y-0 flex-col md:flex-row">
          <button
            className="transition-all hover:border-2"
            onClick={() => handleDirectionRequest("supermarket")}
          >
            <div
              ref={el1}
              className="shadow-md h-80 w-80 p-8 flex flex-col justify-center items-center space-y-8"
            >
              <span className="rounded-full border-2">
                <img
                  src={ShopSvg}
                  className="h-24 w-24"
                  alt="Icon representing a shop"
                />
              </span>
              <p className="text-center">
                <Trans i18nKey={"about_distance_supermarket"}>
                  Super markets and tourist shops are <u>2 minutes</u> away.
                </Trans>
              </p>
            </div>
          </button>
          <button
            ref={el2}
            className="shadow-md h-80 w-80 p-8 transition-all hover:border-2"
            onClick={() => handleDirectionRequest("restaurants")}
          >
            <div className="flex flex-col justify-center items-center space-y-8">
              <span className="rounded-full border-2">
                <img
                  src={RestaurantSvg}
                  className="h-24 w-24"
                  aria-hidden={true}
                  alt="Abstract icon representing a restaurant"
                />
              </span>
              <p className="text-center">
                <Trans i18nKey={"about_distance_dinner"}>
                  The best restaurants in the area are <u>2 minutes</u> away.
                </Trans>
              </p>
            </div>
          </button>
          <button
            ref={el3}
            className="shadow-md h-80 w-80 p-8 transition-all hover:border-2"
            onClick={() => handleDirectionRequest("beach")}
          >
            <div className="flex flex-col justify-center items-center space-y-8">
              <span className="rounded-full border-2">
                <img
                  src={BeachSvg}
                  className="h-24 w-24"
                  alt="Abstract icon representing a beach"
                />
              </span>
              <p className="text-center">
                <Trans i18nKey={"about_distance_beach"}>
                  The local beach is <u>2 minutes</u> away. <br />
                  <br />
                  "Trani Amouda" is just <u>8 minutes</u> on foot.
                </Trans>
              </p>
            </div>
          </button>
        </div>
      </div>
      <GoogleMapsMap ref={mapsRef} direction={directionToShow} />
    </section>
  );
}

export const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <MetaTags
        pageTitle={t("meta_page_title_about")}
        pageDescription={t("meta_page_description_about")}
        canonicalUrlPath="about-us"
        socialMediaTitle={t("social_media_title")}
      />
      <div className="bg-black">
        <HeaderSubsection
          title={t("about_title")}
          description={
            <span className="prose text-xl text-center">
              <p>
                <Trans>{t("about_description")}</Trans>
              </p>
            </span>
          }
        />
      </div>
      <div className="h-24 md:h-24 bg-black" />
      <TodoSection />
      <div className="h-24 md:h-24" />
      <AmenitiesComponent />
      <div className="h-24 md:h-12" />
      <Footer />
    </>
  );
};
