import { Resource } from "i18next";

export const i18EnResource: Resource = {
  en: {
    translation: {
      meta_page_title_about:
        "Learn more about our luxury studios and the area | The Archontariki, Ormos Panagias",
      meta_page_description_about:
        "Learn more about the Archontariki, the studios, Ormos Panagias, Sithonia and Halkidiki.",
      meta_page_title_contact:
        "Contact information for booking and other inquiries | The Archontariki, Ormos Panagias",
      meta_page_description_contact:
        "Contact information for booking or other inquiries.",
      meta_page_title_home:
        "Luxury Studios in Sithonia in Halkidiki | The Archontariki, Ormos Panagias",
      meta_page_description_home:
        "Vacay in Sithonia, in Halkidiki, in clean, modern and luxury studios in The Archontariki.",
      social_media_title: "The Archontariki Luxury Studios",
      top_menu_home: "Home",
      top_menu_about: "About us",
      top_menu_contact: "Contact",
      header_title: "Enjoy our studios in the sunny Sithonia in Halkidiki",
      header_subtitle: `Vacay like never before. Both for the adventurous and the ones
      that just look to relax and enjoy the sun will find the peace they
      require in our luxury studios.`,
      home_block1_title:
        "Enjoy vacations in the most beautiful part of Halkidiki",
      home_block1_body:
        "The Archontariki Studios, in Ormos Panagias, are located on Sithonia, the second peninsula of Halkidiki. Family owned and here to provide you with the most relaxed, mind soothing vacations.",
      home_block2_title: "Sithonia the beauty of Halkidiki",
      home_block2_body:
        "One of the three peninsulas of Halkidiki, features amazing things to do for both the explorers and the one's that are looking to relax and enjoy. Our luxury studios are here to embrace you after a day of adventure, or mind soothin relaxation.",
      about_title: `Informations about the Archontariki studios`,
      about_description: `We are a family owned business. We love our work and strive for the best possible experience. Our clean, luxureous studios have been designed with you in mind. Modern, clean and with a fresh design, they will allow you to truly relax.`,
      about_block1_title: `The freshness of nature at your disposal`,
      about_block1_body: `In Sithonia, the beauty of Halkidiki unfolds simply in front of you.<br/>
      From the blue of the sky to the freshness of the fish. You can enjoy endless possibilities.`,
      about_block2_title: `The Archontariki Studios are located in Ormos Panagias in Sithonia
      in Halkidiki`,
      about_block2_body: `Ormos Panagias, once a fishing village, now a tourist's dream!
      With multiple swimming oportunities nearby there is something even
      for the most avid swimmers among us. Ormos Panagias is also the
      starting point for the scenic boat rides to mount Athos. The
      Archontariki Studios' team can help you plan your trip and enjoy
      the untouched beauty of mount Athos. Finally, after a long day of
      adventure or simply relaxing on the beach, Ormos Panagias's
      renowned fish taverns will sooth your hunger with locally fished
      fish and delicacies.`,
      about_block3_title: `Everything is simply close by...`,
      about_block3_body: `Ormos Pagagias is just <1>1 hour</1> away from the Thessaloniki
      Makedonia Airport (<5>SKG</5>). With multiple car <5>renting locations nearby</5>, getting to Archontariki is a short drive away.
      <br/>
      <br/>
      From shops to taverns, to the beach, everything is then just in
      walking distance. 
      <strong>
        Select one of the bellow options to see the distance from the
        Archontariki.
      </strong>`,
      about_distance_supermarket:
        "Super markets and tourist shops are <1>2 minutes</1> away.",
      about_distance_dinner:
        "The best restaurants in the area are <1>2 minutes</1> away.",
      about_distance_beach: `The local beach is <1>2 minutes</1> away. <br />
        <br />
        "Trani Amouda" is just <1>8 minutes</1> on foot.`,
      contact_title: `Contact information for booking a studio in the Archontariki`,
      contact_description1: `To directly book with us you can contact us on <br/><2>info@thearchontariki.com</2> <br/> or call:<br/><6>+30 693 6558 908</6><br/><7>+30 237 503 1406</7>`,
      contact_description2: `You can also contact us on <2>Facebook</2>`,
      title_contact_information: "Contact information",
      text_ormos_panagias: "Ormos Panagias",
      text_archontariki: "The Archontariki",
    },
  },
  el: {
    translation: {
      meta_page_title_about:
        "Πληροφορίες για τα στουντιο και την περιοχή | Το Αρχονταρίκι, Όρμος Παναγίας",
      meta_page_description_about:
        "Μάθετε περισσότερα για το Αρχονταρίκι και την περιοχή μας.",
      meta_page_title_contact:
        "Πληροφορίες επικοινωνίας για κρατήσεις | Το Αρχονταρίκι, Όρμος Παναγίας",
      meta_page_description_contact:
        "Πληροφορίες επικοινωνίας για κρατήσεις και πιθανές ερωτήσεις.",
      meta_page_title_home:
        "Στουντιο στον Όρμο Παναγίας στην Χαλκιδική | Το Αρχονταρίκι, Όρμος Παναγίας",
      meta_page_description_home:
        "Απολαύστε τις διακοπές σας στην πανέμορφη Χαλκιδική στα ανανεωμένα στούντιο, στο Αρχονταρίκι.",
      social_media_title: "Το Αρχονταρίκι στον Όρμο Παναγίας",
      top_menu_home: "Αρχική",
      top_menu_about: "Πληροφορίες",
      top_menu_contact: "Επικοινωνία",
      header_title: "Απολαύστε τις διακοπές σας στην πανέμορφη Χαλκιδική",
      header_subtitle: `Τα πολυτελή στουντιο μας είναι έτοιμα να σας υποδεχτούν για να απολαύσετε τις διακοπές σας στην πανέμορφη Χαλκιδική.`,
      home_block1_title: "Απολαύστε τις διακοπές σας στην πανέμορφη Σιθωνία",
      home_block1_body:
        "Το Αρχονταρίκι, βρίσκεται στον Όρμο Παναγίας, της Σιθωνίας στην Χαλκιδική. Αγαπάμε αυτό που κάνουμε και είμαστε εδώ για να σας προσφέρουμε ξεχωριστές διακοπες.",
      home_block2_title: "Η πανέμορφη Σιθωνία",
      home_block2_body:
        "Η Σιθωνία είναι το δεύτερο πόδι της Χαλκιδικής. Με την άγρια ομορφιά της αλλά και με τις φανταστικές παροχές της, έχει κάτι να προσφέρει τόσο σε αυτούς που ψάχνουν να ενωθούν με την φύση όσο και με αυτούς που ψάχνουν χαλαρωτικές διακοπές. Το Αρχονταρίκι, και τα πολυτελή στουντιο μας, θα είναι εκεί για να ξεκουραστείτε ανεξαρτήτως του πως θα επιλέξετε να περάσετε την μέρα σας.",
      about_title: `Πληροφορίες για το Αρχονταρίκι`,
      about_description: `Είμαστε μια οικογενειακή επιχείρηση με μεράκι και αγάπη για τη δουλειά μας. <br/><br/>Τα μοντέρνα, τα πεντακάθαρα, και τα πλήρως ανακαινισμένα στούντιο μας, είναι εδώ για να σας παρέχουν πράγματι χαλαρωτικές διακοπες.`,
      about_block1_title: `Οι φρεσκάδα της φύσης στην διάθεση σας`,
      about_block1_body: `Το μπλε του ουρανού, το άκουσμα της θάλασσας, το πράσινο του πεύκου όλα συνδυάζονται και βρίσκονται σε αφθονία στην περιοχής μας.`,
      about_block2_title: `Το Αρχονταρίκι βρίσκεται στον Όρμο Παναγίας στην Χαλκιδική`,
      about_block2_body: `O Όρμος Παναγίας, κάποτε το λιμάνι του Αγίου Νικολάου, είναι σήμερα ένα τουριστικό όνειρο!
      Με πολλές ευκαιρίες κολύμβησης σε κοντινή απόσταση υπάρχει κάτι ακόμη και
      για τους πιο μανιώδεις κολυμβητές ανάμεσά μας. Ό Όρμος Παναγίας είναι επίσης η
      αφετηρία για τις τουριστικές κρουαζιέρες προς το Άγιο Όρος.
      Η ομάδα του Αρχονταρίκι μπορεί να σας βοηθήσει να προγραμματίσετε το ταξίδι σας και να απολαύσετε
      την ανέγγιχτη ομορφιά του Αγίου Όρους. Μετά από μια μέρα εμπειριών και απολαύσεων, με
      περιπέτεια ή απλά χαλάρωση στην παραλία, οι φημισμένες ψαροταβέρνες του Όρμου Παναγίας θα καταπραΰνουν την πείνα σας με ντόπια ψάρια αλλά και με άλλες λιχουδιές.`,
      about_block3_title: `Τα πάντα είναι κοντά σας...`,
      about_block3_body: `
      Μαγαζιά, ταβερνες, ακόμα και η παραλία, είναι διπλα σας.
      <strong>
        Επιλέξτε από τις παρακάτω επιλογές για να δείτε τις αποστάσεις απο το Αρχονταρίκι.
      </strong>`,
      about_distance_supermarket:
        "Σούπερ μάρκετ και τουριστικά μαγαζια, μόλις <1>2 λεπτά</1> περπάτημα.",
      about_distance_dinner:
        "Οι καλύτερες ψαροταβέρνες της περιοχής, <1>2 λεπτά</1> περπάτημα.",
      about_distance_beach: `Η παραλία του Όρμου Παναγίας, <1>2 λεπτά</1> μακριά. <br />
        <br />
        Η "Τρανή Αμμούδα", <1>8 λεπτά</1> περπάτημα, μα ακόμα πιο κοντά με αυτοκίνητο.`,
      contact_title: `Πληροφορίες επικοινωνίας`,
      contact_description1: `Για όποια ερώτηση και για κρατήσεις επικοινωνήστε μαζί μας: <br/><2>info@thearchontariki.com</2><br/>Κινητό: <6>693 6558 908</6><br/>Σταθερό: <7>237 503 1406</7>`,
      contact_description2: `Μπορείτε επίσης να επικοινωνήστε μαζί μας μέσω <2>Facebook</2>`,
      title_contact_information: "Πληροφορίες επικοινωνίας",
      text_ormos_panagias: "Όρμος Παναγίας",
      text_archontariki: "Το Αρχονταρίκι",
    },
  },
};
