import { forwardRef, useRef } from "react";
import { Header } from "../components/Header";
import {
  useFadeOnIntersectWithViewport,
  useMoveUpOnIntersectWithViewport,
} from "../intersection-hooks";
import { Footer } from "../components/Footer";
import { MetaTags } from "../components/MetaTags";
import roomImage from "../images/room_1.jpeg";
import sithoniaWaterImg from "../images/sithonia-amazing-water-color.jpeg";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const SectionHeader = forwardRef<HTMLHeadingElement, { text: string }>(
  (props: { text: string }, ref) => {
    return (
      <h2 ref={ref} className="font-serif md:text-2xl text-xl font-thin">
        {props.text}
      </h2>
    );
  }
);

function AboutArchontarikiComponent() {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  useFadeOnIntersectWithViewport([titleRef, textRef, imageRef]);
  useMoveUpOnIntersectWithViewport([messageContainerRef]);
  const { t } = useTranslation();

  return (
    <section className="flex justify-center items-center">
      <div className="container relative">
        <div
          ref={messageContainerRef}
          className="relative md:absolute md:left-0 top-1/4 md:z-10"
        >
          <div className="my-4 w-full md:w-1/2 text-center bg-white p-12 shadow-md">
            <span className="prose">
              <SectionHeader ref={titleRef} text={t("home_block1_title")} />
              <p ref={textRef} className="font-sans md:text-lg text-md">
                {t("home_block1_body")}
              </p>
            </span>
          </div>
        </div>
        <div className="flex justify-end items-end overflow-hidden flex-col md:ml-48">
          <figure>
            <img
              ref={imageRef}
              src={roomImage}
              style={{
                height: "100%",
                width: "100%",
              }}
              alt="A uniquely designed room in the Archontariki"
            />
          </figure>
        </div>
      </div>
    </section>
  );
}

function AboutSithoniaComponent() {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  useFadeOnIntersectWithViewport([titleRef, textRef]);
  const { t } = useTranslation();
  return (
    <section className="relative overflow-hidden md:h-[800px] flex justify-center items-center flex-col-reverse md:flex-col">
      <div className="md:absolute md:-z-10 w-full h-86">
        <img
          src={sithoniaWaterImg}
          className="cover-fill"
          style={{
            height: "100%",
            width: "100%",
          }}
          alt="The blue waters of the sea found near by the Archontariki Studios in Sithonia in Halkidiki"
        />
      </div>
      <div className="container relative">
        <div className="flex items-center md:justify-end">
          <div className="my-4 w-full p-12 md:w-1/2 bg-white md:p-16 shadow-md z-0 text-center block md:absolute">
            <span className="prose">
              <SectionHeader ref={titleRef} text={t("home_block2_title")} />
              <p ref={textRef} className="font-sans md:text-lg text-md">
                {t("home_block2_body")}
              </p>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export const Home = () => {
  const { t } = useTranslation();
  return (
    <div>
      <MetaTags
        pageTitle={t('meta_page_title_home')}
        pageDescription={t('meta_page_description_home')}
        canonicalUrlPath=""
        socialMediaTitle={t("social_media_title")}
      />
      <Header />
      <div className="h-4 md:h-48 divide-y" />
      <AboutArchontarikiComponent />
      <div className="h-4 md:h-48 divide-y" />
      <AboutSithoniaComponent />
      <div className="h-4 md:h-12 divide-y" />
      <Footer />
    </div>
  );
};
