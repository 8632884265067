import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  Analytics,
  setCurrentScreen,
  logEvent,
} from "firebase/analytics";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const firebaseConfig = {
  apiKey: "AIzaSyBlIGYVuQCVeS-olb7CE57POm0b13ePjjw",
  authDomain: "the-archontariki-studios.firebaseapp.com",
  projectId: "the-archontariki-studios",
  storageBucket: "the-archontariki-studios.appspot.com",
  messagingSenderId: "545106119101",
  appId: "1:545106119101:web:55f47f24609588304a6ad4",
  measurementId: "G-Q2P4W8V2P5",
};

export const defaultApp = initializeApp(firebaseConfig);
export const defaultAnalytics = getAnalytics(defaultApp);

export const useFirebaseAnalytics = (
  app: ReturnType<typeof initializeApp> = defaultApp
): Analytics => {
  const [analyticsInstance, setAnalyticsInstance] = useState<
    ReturnType<typeof getAnalytics>
  >(getAnalytics(app));
  useEffect(() => {
    setAnalyticsInstance(getAnalytics(app));
  }, [app]);

  return analyticsInstance;
};

export const useFirebaseAnalyticsCurrentScreen = () => {
  const location = useLocation();
  const analytics = useFirebaseAnalytics();
  useEffect(() => {
    const path = `${location.pathname}${location.search}`;
    setCurrentScreen(analytics, path);
    logEvent(analytics, "page_view", { page_path: path });
  }, [analytics, location.pathname, location.search]);
};
