import { Wrapper } from "@googlemaps/react-wrapper";
import { forwardRef, useEffect, useRef } from "react";

const archontarikiLocation = {
  lat: 40.2341382,
  lng: 23.7303514,
};

const superMarketLocation = {
  lat: 40.234776,
  lng: 23.731012,
};

const restaurantsLocation = {
  lat: 40.233789,
  lng: 23.731744,
};

const traniAmoudaLocation = {
  lat: 40.23746,
  lng: 23.729825,
};

export const GoogleMapsMap = forwardRef<
  HTMLDivElement,
  {
    direction: KnownDirections;
  }
>((props, ref) => {
  return (
    <div ref={ref} className="w-full h-full">
      <Wrapper apiKey="AIzaSyDYpR0Gol57ZrPZaCjFSDtnQ5SQRvg4Ofc">
        <Map {...props} />
      </Wrapper>
    </div>
  );
});

export type KnownDirections =
  | "none"
  | "restaurants"
  | "airport"
  | "beach"
  | "supermarket";

export const Map = (props: { direction: KnownDirections }) => {
  const ref = useRef(null);
  const directionsService = useRef(new google.maps.DirectionsService());
  const directionsRenderer = useRef(new google.maps.DirectionsRenderer());

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current!, {
      center: {
        lat: 40.2336744,
        lng: 23.7312361,
      },
      mapTypeControl: false,
      mapTypeId: "satellite",
      zoom: 17,
    });
    new google.maps.Marker({
      position: archontarikiLocation,
      title: "The Archontariki",
      clickable: true,
      map: map,
    });
    directionsRenderer.current.setMap(map);
  });

  useEffect(() => {
    switch (props.direction) {
      case "airport":
        return calcRouteToMakedoniaAirport();
      case "beach":
        return calcRouteToTraniAmouda();
      case "restaurants":
        return calcRouteToRestaurant();
      case "supermarket":
        return calcRouteToSuperMarket();
      default:
        removeDirection();
    }
  }, [props.direction]);

  function removeDirection() {
    directionsRenderer.current.setDirections(null);
  }

  function calcRouteToMakedoniaAirport() {
    directionsRenderer.current.setDirections(
      require("./direction-makedonia.json")
    );
  }

  function calcRouteToSuperMarket() {
    directionsRenderer.current.setOptions({
      directions: require("./direction-super-market.json"),
    }
    );
  }

  function calcRouteToRestaurant() {
    directionsRenderer.current.setOptions({
      directions: require("./direction-restaurants.json"),
    });
  }

  function calcRouteToTraniAmouda() {
    directionsRenderer.current.setOptions({
      directions: require("./direction-trani-amouda.json"),
    });
  }

  function getRoute() {
    directionsService.current.route(
      {
        origin: archontarikiLocation,
        destination: traniAmoudaLocation,
        travelMode: google.maps.TravelMode.WALKING,
      },
      (route, status) => {
        if (status === "OK") {
          console.log(JSON.stringify(route));
        }
      }
    );
  }

  return (
    <div className="w-full h-full">
      <div className="relative flex w-full h-[500px]">
        <div className="absolute bg-white h-64 w-64"></div>
        <div ref={ref} className="w-full"></div>
      </div>
    </div>
  );
};
