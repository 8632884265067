import { PropsWithChildren } from "react";
import { Link, LinkProps } from "react-router-dom";

export function LinkTopMenu(props: PropsWithChildren<LinkProps>) {
  return (
    <Link
      className="transition ease-in cursor-pointer hover:text-blue-500"
      {...props}
    />
  );
}
