import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const HomepageTitle = () => {
  const { t } = useTranslation();
  return (
    <Link
      to="/"
      aria-label="Navigate to the Archontariki home page"
      className="no-underline font-serif flex h-full"
    >
      <div className="text-2xl justify-center items-center flex">{t("text_archontariki")}</div>
    </Link>
  );
};
