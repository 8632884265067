import { RefObject, useEffect, useState } from "react";

export const useIntersectionObserver = (
  callback: IntersectionObserverCallback,
  options?: IntersectionObserverInit
) => {
  const [intersectionObserver] = useState(
    new IntersectionObserver(callback, options)
  );
  return intersectionObserver;
};

export const useFadeOnIntersectWithViewport = <T extends HTMLElement>(
  refs: Array<RefObject<T>>
) => {
  useIntersectionObserving(refs, intersectCallback);
  function intersectCallback(
    entries: IntersectionObserverEntry[],
    _: IntersectionObserver
  ) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("animate-fadeIn");
      }
    });
  }
};

export const useMoveUpOnIntersectWithViewport = <T extends HTMLElement>(
  refs: Array<RefObject<T>>,
  options?: { delay: number }
) => {
  useIntersectionObserving(refs, intersectCallback, undefined, (el) =>
    el.classList.add("opacity-0")
  );
  function intersectCallback(
    entries: IntersectionObserverEntry[],
    _: IntersectionObserver
  ) {
    entries.forEach((entry, idx) => {
      if (entry.isIntersecting) {
        setTimeout(() => {
          entry.target.classList.add("animate-moveUp");
        }, idx * (options?.delay ?? 0));
      }
    });
  }
};

export const useIntersectionObserving = <T extends HTMLElement>(
  refs: Array<RefObject<T>>,
  callback: IntersectionObserverCallback,
  observerInit?: IntersectionObserverInit,
  modify?: (el: T) => void
) => {
  const intersectionObserver = useIntersectionObserver(callback, observerInit);
  useEffect(() => {
    refs
      .filter((ref) => ref.current !== null)
      .forEach((ref) => {
        modify?.(ref.current!);
        intersectionObserver.unobserve(ref.current!);
        intersectionObserver.observe(ref.current!);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersectionObserver, refs]);
  return intersectionObserver;
};
