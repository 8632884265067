import { AppLinks } from "./AppLinks";
import { HomepageTitle } from "./HomepageTitle";
import GoogleRating from "../images/google-rating.png";
import BookingRating from "../images/booking-rating.png";
import { useTranslation } from "react-i18next";

export function Footer() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center">
      <div className="container flex w-full mb-8 col-span-3 md:justify-around flex-col items-center divide-y-2 md:divide-y-0 space-y-5 md:space-y-0 md:flex-row">
        <div className="flex justify-center items-center flex-col pt-8">
          <div className="grid grid-flow-row gap-y-8 w-full justify-center mb-8">
            <img
              src={GoogleRating}
              width="120"
              alt="Archontariki Studios scores 4.8 on Google"
            />
            <img
              src={BookingRating}
              width="120"
              alt="Archontariki Studios scores 9.2 on booking.com"
            />
          </div>
        </div>
        <div className="flex flex-col space-y-8 md:items-start pt-4 w-full md:w-auto items-center">
          <span className="text-xl">Menu</span>
          <div>
            <nav className="flex space-y-4 flex-col md:items-start items-center">
              <AppLinks />
            </nav>
          </div>
        </div>
        <div className="flex flex-col space-y-8 pt-4 w-full md:w-auto">
          <span className="text-xl text-center">
            {t("title_contact_information")}
          </span>
          <span className="flex flex-col items-center">
            <span>
              <HomepageTitle />
            </span>
            <span>{t("text_ormos_panagias")}, 630 78,</span>
            <span>
              <a href="mailto:info@thearchontariki.com">
                mail us on info@thearchontariki.com,
              </a>
            </span>
            <span>
              <a href="tel:+306936558908">or call +30 693 6558 908</a>
            </span>
            <span>
              <a href="tel:+302375031406">or call +30 237 5031 406</a>
            </span>
          </span>
        </div>
      </div>
      <span className="text-sm">
        {t("text_archontariki")}, ΜΗΤΕ: 09.38.Κ.11.3Κ.04521.46.01
      </span>
    </div>
  );
}
