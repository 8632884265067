import { AppLinks } from "./AppLinks";
import { BookNowButton } from "./Button";
import { HomepageTitle } from "./HomepageTitle";

export const Menu = () => {
  return (
    <div className="container">
      <div className="flex justify-between h-28 flex-row bg-white items-center px-4">
        <div className="flex items-center space-x-16">
          <span className="text-xl prose">
            <HomepageTitle />
          </span>
          <nav className="flex space-x-8 text-xl items-center">
            <AppLinks />
          </nav>
        </div>
        <BookNowButton />
      </div>
    </div>
  );
};
