import { useRef } from "react";
import { useFadeOnIntersectWithViewport } from "../intersection-hooks";
import { Gap } from "./Gap";
import { Menu } from "./Menu";
import { MobileMenu } from "./MobileMenu";
import RoomImage from "../images/room_2.jpeg";
import GoogleRating from "../images/google-rating.png";
import BookingRating from "../images/booking-rating.png";
import { useTranslation } from "react-i18next";

export const Header = () => {
  const titleRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  useFadeOnIntersectWithViewport([titleRef, textRef]);
  const { t } = useTranslation();
  return (
    <div className="w-screen flex justify-center items-center bg-white">
      <div className="absolute min-h-screen w-full md:w-1/2 right-0 top-0 bottom-0 overflow-hidden">
        <div className="relative h-full w-full">
          <img
            src={RoomImage}
            alt=""
            className="object-cover"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div>
      <header className="container relative h-screen flex justify-between flex-col min-h-[700px]">
        <div>
          <span className="absolute top-6 w-full hidden md:block">
            <Menu />
          </span>
          <MobileMenu />
        </div>
        <div className="w-2/3 md:mt-0">
          <div
            ref={titleRef}
            className="font-medium text-4xl leading-tight pl-4 md:pl-0 xl md:text-6xl text-left"
          >
            <h1 className="bg-slate-50/90 md:bg-transparent">
              {t("header_title")}
            </h1>
          </div>
          <Gap height={48} />
          <div
            ref={textRef}
            className="font-sans text-left text-xl w-4/5 md:w-3/5 pl-4 md:pl-0"
          >
            <span className="bg-slate-50/90 md:bg-transparent font">
              {t("header_subtitle")}
            </span>
          </div>
        </div>
        <div className="grid grid-flow-col columns-2 w-full md:w-1/2 justify-around mb-8">
          <img
            src={GoogleRating}
            width="120"
            alt="Archontariki Studios scores 4.8 on Google"
          />
          <img
            src={BookingRating}
            width="120"
            alt="Archontariki Studios scores 9.2 on booking.com"
          />
        </div>
      </header>
    </div>
  );
};
